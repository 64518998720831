import { useState } from "react";

export default function isAuthenticate() {
  function checkToken() {
    try {
      const accessToken = sessionStorage.getItem("accesstoken");
      return accessToken !== null;
    } catch (error) {
      console.error("Error accessing access token:", error);
      return false;
    }
  }

  const hasAccessToken = checkToken();
  if (hasAccessToken) {
    return true;
  } else {
    return false;
  }

  return hasAccessToken;
}
