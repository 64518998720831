import { useEffect, useState } from "react";
export const useClassObserver = (targetClass: string) => {
  const [classExists, setClassExists] = useState(false);
  useEffect(() => {
    // Function to check if the class exists in the DOM
    const checkClass = () => {
      const element = document.querySelector(`.${targetClass}`);
      setClassExists(!!element); // If the element is found, set classExists to true
    };
    // Initial check
    checkClass();
    // Set up the observer to watch for changes in the DOM
    const observer = new MutationObserver(checkClass);
    // Start observing the document's body for changes
    observer.observe(document.body, {
      childList: true,
      subtree: true,
      characterData: true,
      characterDataOldValue: true,
      attributes: true,
    });
    // Cleanup when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, [targetClass]);
  return classExists;
};
